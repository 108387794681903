// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { configDigitalSigningModel } from './digital-signing/configDigitalSigningModel';
import { erstConfigModel } from './erst/erstConfigModel';

export class ConfigModel {
  digitalSigning: configDigitalSigningModel;
  erstSubmitService: erstConfigModel;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.digitalSigning = data.digitalSigning ? new configDigitalSigningModel(data.digitalSigning) : null;
    this.erstSubmitService = data.erstSubmitService ? new erstConfigModel(data.erstSubmitService) : null;
  }
}

function validateInput(input: any) {
  let digitalSigningIsObject: boolean = true;
  let erstSubmitServiceIsObject: boolean = true;
  if (input.digitalSigning) digitalSigningIsObject = UserInputValidationService.objectInput(input.digitalSigning, 'ConfigModel');
  if (input.digitalSigning) erstSubmitServiceIsObject = UserInputValidationService.objectInput(input.erstSubmitService, 'ConfigModel');

  if (!digitalSigningIsObject || !erstSubmitServiceIsObject) return false;
  return true;
}