<div class="erst-submission-step-regnskaber-container">
  <div class="ixbrl-container">
    <div class="file-drop-header">IXBRL-instansfil:</div>
    <file-uploader instructions="Drop IXBRL-filen her eller klik på Vælg filer." [acceptedTypes]="['application/xhtml+xml']"
      [multipleSelection]="false" [showFilesUnder]="true" (fileUpload)="fileUpload($event, 'ixbrl')" (fileRemove)="fileRemove('ixbrl')"
      [files]="model.ixbrlFilePush.asObservable()">
    </file-uploader>
  </div>

  <div class="pdf-container">
    <div class="file-drop-header">Pdf-fil:</div>
    <file-uploader instructions="Drop pdf-filen her eller klik på Vælg filer." [acceptedTypes]="['application/pdf']"
      [multipleSelection]="false" [showFilesUnder]="true" (fileUpload)="fileUpload($event, 'pdf')" (fileRemove)="fileRemove('pdf')"
      [files]="model.pdfFilePush.asObservable()">
    </file-uploader>
  </div>

  <div class="xbrl-container">
    <div class="file-drop-header">XBRL-instansfil:</div>
    <file-uploader instructions="Drop XBRL-filen her eller klik på Vælg filer." [acceptedTypes]="['text/xml']" [multipleSelection]="false"
      [showFilesUnder]="true" (fileUpload)="fileUpload($event, 'xbrl')" (fileRemove)="fileRemove('xbrl')"
      [files]="model.xbrlFilePush.asObservable()">
    </file-uploader>
  </div>

  <div class="xbrl-revenue-container">
    <div class="file-drop-header">XBRL-instansfil nettoomsætning:</div>
    <file-uploader instructions="Drop XBRL-nettoomsætingsfilen her eller klik på Vælg filer." [acceptedTypes]="['text/xml']"
      [multipleSelection]="false" [showFilesUnder]="true" (fileUpload)="fileUpload($event, 'xbrlRevenue')"
      (fileRemove)="fileRemove('xbrlRevenue')" [files]="model.xbrlRevenueFilePush.asObservable()">
    </file-uploader>
  </div>
</div>