// Services
import { UserInputValidationService } from 'src/app/_services';
// Models
import { erstConfigXbrlModel } from './erstConfigXbrlModel';

export class erstConfigModel {
  xbrl: erstConfigXbrlModel;

  constructor(data: any) {
    if (!validateInput(data)) return null;
    this.xbrl = this.addSubmitModel(data);
  }
  addSubmitModel(data: any): erstConfigXbrlModel {
    let submitModel = new erstConfigXbrlModel(data.xbrl);
    return Object.values(submitModel).length ? submitModel : null;
  }
}

function validateInput(input: any): boolean {
  let erstConfigModelIsObject: boolean = UserInputValidationService.objectInput(input, 'erstConfigModel');
  if (!erstConfigModelIsObject) return false;
  return erstConfigModelIsObject;
}