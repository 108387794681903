// Angular
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// NgRx
import { Store } from '@ngrx/store';
// Services
import { WpDataRetrieverService } from 'src/app/_services';
import { ConfigService } from 'src/app/_services';
// Models
import { FileDetailsModel } from 'src/app/_models/common';
import { IErstDocumentsSuggestion } from '../_models/interfaces';

@Injectable({
  providedIn: 'root'
})

export class ErstSuggestionService {
  constructor(
    private wpDataRetrieverService: WpDataRetrieverService,
    private configService: ConfigService,
    private store: Store,
  ) { }

  public getSuggestion(): Observable<IErstDocumentsSuggestion> {
    return this.getDocumentSuggestion();
  };

  private getDocumentSuggestion(): Observable<IErstDocumentsSuggestion> {
    let observable$: Observable<IErstDocumentsSuggestion> = of(null);
    let configSuggestionDocument = this.configService.getErstSubmitDocumentsSuggestion('XBRL');

    let suggestionDocumentsId = ['ED2', 'ED1', 'ED5'];
    if (configSuggestionDocument) {
      if (configSuggestionDocument.pdfFile) suggestionDocumentsId[0] = configSuggestionDocument.pdfFile.documentId;
      if (configSuggestionDocument.xbrlFile) suggestionDocumentsId[1] = configSuggestionDocument.xbrlFile.documentId;
      if (configSuggestionDocument.xbrlRevenueFile) suggestionDocumentsId[2] = configSuggestionDocument.xbrlRevenueFile?.documentId;
    }

    observable$ = forkJoin([this.getDocuments(suggestionDocumentsId)].flat(1)).pipe(
      map((documents: FileDetailsModel[]) => {
        if (documents[1]?.type === 'application/xhtml+xml') {
          return {
            ixbrl: documents[1],
            pdf: null,
            xbrl: null,
            xbrlRevenue: documents[2]?.type === 'text/xml' ? documents[2] : null,
          };
        }
        return {
          ixbrl: null,
          pdf: documents[0]?.type === 'application/pdf' ? documents[0] : null,
          xbrl: documents[1]?.type === 'text/xml' ? documents[1] : null,
          xbrlRevenue: documents[2]?.type === 'text/xml' ? documents[2] : null,
        };
      })
    );
    return observable$;
  }

  private getDocuments(documents: any[]): Observable<FileDetailsModel>[] {
    let observable$ = documents.map(e => {
      return this.wpDataRetrieverService.getDocumentObservable(e);
    });
    return observable$;
  }
};
